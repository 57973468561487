/*
  	Flaticon icon font: Flaticon
  	Creation date: 18/05/2019 14:46
*/
@font-face {
	font-family: "Flaticon";
	src: url("../fonts/Flaticon/Flaticon.eot");
	src: url("../fonts/Flaticon/Flaticond41d.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Flaticon/Flaticon.woff2") format("woff2"),
		url("../fonts/Flaticon/Flaticon.woff") format("woff"),
		url("../fonts/Flaticon/Flaticon.ttf") format("truetype"),
		url("../fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'Flaticon';
		src: url("../fonts/Flaticon/Flaticon.svg#Flaticon") format("svg")
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after { 
	font-family: Flaticon;
	font-style: normal
}
.flaticon-up-arrow:before { content: "\f100" }
.flaticon-download-arrow:before { content: "\f101" }
.flaticon-right-arrow:before { content: "\f102" }
.flaticon-arrow-pointing-to-right:before { content: "\f103" }
.flaticon-steering-wheel:before { content: "\f104" }
.flaticon-car:before { content: "\f105" }
.flaticon-taxi:before { content: "\f106" }
.flaticon-car-1:before { content: "\f107" }
.flaticon-quote:before { content: "\f108" }
.flaticon-left-arrow:before { content: "\f109" }
.flaticon-left-arrow-1:before { content: "\f10a" }
.flaticon-car-wash:before { content: "\f10b" }
.flaticon-tire:before { content: "\f10c" }
.flaticon-glass-cleaning:before { content: "\f10d" }
.flaticon-brush:before { content: "\f10e" }
.flaticon-car-2:before { content: "\f10f" }
.flaticon-tick:before { content: "\f110" }
.flaticon-letter-x:before { content: "\f111" }
.flaticon-play-button:before { content: "\f112" }
.flaticon-send:before { content: "\f113" }
.flaticon-drop-up-arrow:before { content: "\f114" }
.flaticon-engine:before { content: "\f115" }
.flaticon-fuel-station:before { content: "\f116" }
.flaticon-speedometer:before { content: "\f117" }
.flaticon-car-insurance:before { content: "\f118" }
.flaticon-painting:before { content: "\f119" }
.flaticon-car-repair:before { content: "\f11a" }
.flaticon-car-3:before { content: "\f11b" }
.flaticon-car-4:before { content: "\f11c" }
.flaticon-service:before { content: "\f11d" }
.flaticon-search:before { content: "\f11e" }
.flaticon-auto-sale:before { content: "\f11f" }
.flaticon-freeze:before { content: "\f120" }
.flaticon-car-service:before { content: "\f121" }
.flaticon-phone-call:before { content: "\f122" }
.flaticon-placeholder:before { content: "\f123" }
.flaticon-small-calendar:before { content: "\f124" }
.flaticon-pin:before { content: "\f125" }
.flaticon-star:before { content: "\f126" }
.flaticon-luggage:before { content: "\f127" }
.flaticon-safety-seat:before { content: "\f128" }
.flaticon-car-5:before { content: "\f129" }
.flaticon-no-smoking:before { content: "\f12a" }
.flaticon-heart:before { content: "\f12b" }
.flaticon-comment:before { content: "\f12c" }
.flaticon-share:before { content: "\f12d" }
.flaticon-next:before { content: "\f12e" }
.flaticon-favorite-heart-button:before { content: "\f12f" }
.flaticon-right-arrow-1:before { content: "\f130" }
.flaticon-favorite:before { content: "\f131" }
.flaticon-shopping-cart:before { content: "\f132" }
.flaticon-star-made-of-triangles:before { content: "\f133" }
.flaticon-star-sign:before { content: "\f134" }
.flaticon-favourite-star:before { content: "\f135" }
.flaticon-ornamental-star:before { content: "\f136" }
.flaticon-call-answer:before { content: "\f137" }
.flaticon-black-back-closed-envelope-shape:before { content: "\f138" }
.flaticon-lock:before { content: "\f139" }
.flaticon-man-user:before { content: "\f13a" }
.flaticon-email:before { content: "\f13b" }
.flaticon-avatar:before { content: "\f13c" }
.flaticon-global:before { content: "\f13d" }
.flaticon-vehicle-speedometer:before { content: "\f13e" }
.flaticon-speedometer-1:before { content: "\f13f" }
.flaticon-signal-tower:before { content: "\f140" }
.flaticon-paper-clip:before { content: "\f141" }
.flaticon-caret-down:before { content: "\f142" }
.flaticon-warning-sign:before { content: "\f143" }
.flaticon-exclamation:before { content: "\f144" }
.flaticon-car-service-1:before { content: "\f145" }
.flaticon-car-service-2:before { content: "\f146" }
.flaticon-car-service-3:before { content: "\f147" }
.flaticon-car-wash-1:before { content: "\f148" }
.flaticon-car-service-4:before { content: "\f149" }