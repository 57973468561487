@media(max-width: 1499px) {
    .jumbo-container {
        max-width: 1200px;
        width: 100%
    }
    .pricing-page {
        .pricing-header-background {
            width: 100%
        }
    }
}
@media(min-width: 1500px) {
   #root.front .jumbo-container {
        max-width: 1200px
    }
}
@media(max-width: 1200px) {
    .pricing-page {
        .pricing-tble-grid {
            overflow-x: auto;
            padding-left: 300px
        }
    }
}
@media(max-width: 1024px) {
    .pricing-page {
        .pricing-header-background {
            width: 100%
        }
        .pricing-tble-grid, .pricing-detail-box {
            .grid-Box {
                margin: 70px 10px;
                min-width: 250px
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    #root.front {
        .search-bar-mobile-hide {
            display: none !important
        }
        .section.car-select-form{
            .nav-tabs {
                display: none
            }
        }
        .mobile-jumbo{
            flex-direction: column
        }
        .for-mobile-search {
            width: 100%;
            display: block;
            position: absolute;
            top: 70px;
            left: 0;
            right: 0;
            margin: auto;
            padding-bottom: 10px;
            form.search-bar {
                text-align: center;
                background: $Dark;
                padding-bottom: 15px;      
                z-index: 3;
                position: relative;
                input[type="text"] {
                    border: 2px solid #dddddd;
                    padding: 10px 15px;
                    height: 40px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    width: calc(100% - 86px);
                    background: $White
                }
                button {
                    border: 2px solid #dddddd;
                    vertical-align: top;
                    border-left: 0;
                    width: 50px;
                    padding: 10px 15px;
                    background: $White;
                    color: $Dark;
                    height: 40px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px
                }
            }
            .for-mobile-search-result {
                background: #fff;
                width: calc(100% - 50px);
                margin: auto;
                max-height: 500px;
                overflow: scroll;
                z-index: 9;
                position: relative;
                text-align: left;
                li {
                    padding: 10px
                }
            }
        }
        .block-m {
            display: block !important
        }
        .heading-02 {
            font-size: 34px;
            line-height: 45px
        }
        .banner {
            .slider-items {
                .slider-items-wrap {
                    position: relative;
                    width: 100%;
                    z-index: 0;
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: rgba(224, 229, 233, .8);
                        z-index: 0
                    }
                }
                .slider-inner {
                    min-height: calc(100vh - 110px)
                }
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: rgba(224, 229, 233, .9);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1
                }
            }
            .slider-content-bottom {
                display: none
            }
        }
        .for-mobile {
            display: block !important
        }
        .for-desktop {
            display: none !important
        }
        .col-6,
        .col-4,
        .col-8 {
            flex: 0 0 100%;
            max-width: 100% !important;
            width: 100% !important
        }
        .margin-bottom-60 {
            margin-bottom: 60px
        }
        .main-section {
            .banner {
                height: auto;
                img {
                    height: auto
                }
            }
            .section-head {
                h1.title {
                    font-size: 34px;
                    padding: 0 0 15px;
                    line-height: 46px
                }
                p {
                    font-size: 15px
                }
            }
            .car-select-form {
                padding: 0 20px;
                .home-02_tab-section {
                    .tab-wrapper {
                        width: 100%;
                        .nav-tabs {
                            .nav-item {
                                width: 100%;
                                max-width: 300px;
                                a {
                                    min-width: 100%
                                }
                            }
                        }
                    }
                    .tab-content {
                        .select-box-wrap {
                            flex-wrap: wrap;
                            display: block;
                            .items {
                                width: 33.33%;
                                display: inline-block;
                                .custom-select-box {
                                    padding: 10px;
                                    select {
                                        width: 100%;
                                        margin: 0;
                                        padding: 20px
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .our-service {
                .main-wrapper {
                    display: block;
                    .left-image-section, .right-content-section {
                        max-width: 100%;
                        padding: 0 15px;
                        .service-section-bottom {
                            padding: 45px 0 0 0;
                            .items-wrap {
                                display: block;
                                .home-02-our-service-items {
                                    width: 49%;
                                    display: inline-block;
                                    margin-left: 1%;
                                    .item-right {
                                        width: 100%
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .secure_Sec {
            .main-secure {
                display: block;
                .item {
                    max-width: 50%;
                    display: inline-block
                }
            }
        }
        .product-page {
            .product-page-body {
                display: block;
                .product-page-wrapper {
                    max-width: 100%;
                    .grid-list-wrapper {
                        .grid-list-column-item {
                            .main-btn-wrap {
                                .main-btn.black-border {
                                    min-width: auto
                                }
                            }
                        }
                    }
                }
                .product-right-wrapper {
                    max-width: 100%
                }
            }
        }
        .productdetail-page {
            .main-product-detail {
                display: block;
                padding: 0 15px;
                .left-col-box, .left-col, .right-col {
                    width: 100%;
                    margin-left: 0px
                }
                .left-col-box {
                    width: 100%;
                    position: relative;
                    top: 0;
                    .left-col {
                        display: flex;
                        flex-direction: column-reverse;
                        max-width: 420px;
                        margin: auto;
                        .gallery {
                            width: 100%;
                            .images-section {
                                margin-top: 30px;
                                display: flex;
                                overflow: auto;
                                .img-tem {
                                    margin-left: 10px;
                                    min-width: 100px;
                                    margin: auto
                                }
                            }
                        }
                        .image-section {
                            width: 100%;
                            margin: 0
                        }
                    }
                }
               
                .subscribe-btn {
                    margin-left: 0px
                }
            }
            .full-screen-content-sec {
                .content-box {
                    h2 {
                        margin-bottom: 30px
                    }
                    .content-block {
                        width: 100%;
                        padding: 0 15px
                    }
                }
            }
            .half-part-img-content-sec {
                margin-bottom: 50px;
                display: flex;
                .sec-wrapper {
                    width: 50%;
                    display: inline-block;
                    margin: auto;
                    .image-section {
                        width: 100%
                    }
                    .content-section {
                        margin: 50px 0;
                        width: 100%;
                        flex: 1 1;
                        padding: 0 15px;
                        text-align: center
                    }
                }
            }
        }
        .product-page-wrapper {
            max-width: 100% !important;
            .grid-list-wrapper {
                .grid-list-column-item {
                    width: 100%;
                    padding: 10px !important;
                    .main-btn-wrap {
                        .main-btn.black-border {
                            min-width: auto !important
                        }
                    }
                }
            }
        }
        .blog-details-wrap {
            .blog-details-items {
                .blog-details-inner {
                    width: 100%;
                    display: block;
                    .thumb, .content {
                        width: 100%
                    }
                }
            }
            .blog-details-footer {
                .left {
                    .icon-wrap {
                        width: 100%
                    }
                }
                .right {
                    .list-wrap {
                        width: 100%
                    }
                }
            }
        }
        .contact-page {
            .left-image-section, .right-form-section {
                width: 100%;
                img {
                    height: auto
                }
            }
        }
        .profile-page {
            .profile-main-section {
                .left-menu-section, .right-content-section {
                    .profile-sec {
                        .profile-name-section, .form-editable-section {
                            padding: 0;
                            margin: 20px auto;
                            .form-box {
                                max-width: 100%;
                            }
                        }
                    }
                }
              
            }
        }
        .blog-page {
            .blog-page-content {
                .wrapper {
                    .blog-single-item {
                        display: inline-block;
                        width: 50%;
                        padding-left: 15px
                    }
                }
            }
        }
        .how-we-are {
            .side-form-icons {
                display: none;
                position: fixed;
                left: 70px;
                top: auto;
                bottom: -30px;
                transform: rotate(90deg);
                transition: all 0.3s ease
            }
        }
        .profile-setup {
            ul {
                li {
                    span {
                        color: #181818 !important
                    }
                }
            }
            .profile-box {
                ion-icon {
                    font-size: 24px;
                    color: #fff !important;
                    margin: 7px 10px 15px !important;
                    line-height: 50px
                }
            }
        }
        .wrapper {
            display: block !important
        }
        .testimonials {
            padding-bottom: 80px !important;
            .left-img-sec {
                width: 100% !important;
                img {
                    margin: 0 auto !important;
                    display: block !important
                }
            }
            .right-content-sec {
                width: 100% !important;
                padding: 0 15px !important;
                .items {
                    width: 100% !important
                }
            }
        }
        .main-section {
            .our-working-process {
                .page-division-flex {
                    .working-sec {
                        width: 100%;
                        .icon-box-wrapper {
                            padding: 75px 20px 0 20px;
                            text-align: center
                        }
                        .image-part {
                            padding: 40px 0 0
                        }
                    }
                    display: flex;
                    .left-working, .right-working {
                        padding: 0
                    }
                }
            }
        }
        .secure_Sec {
            .main-secure {
                display: block;
                .item {
                    max-width: 50%;
                    display: inline-block
                }
            }
        }
        .footer {
            .jumbo-container {
                .footer-box-wrapper {
                    padding-top: 80px;
                    display: block;
                    .col-4, .col-2, .col-3 {
                        max-width: 100%;
                        position: relative;
                        padding: 0 15px;
                        display: inline-block;
                        margin-bottom: 20px;
                        vertical-align: top;
                        width: 50%;
                        .icon {
                            padding-right: 10px;
                            float: left
                        }
                    }
                    .m-l-8 {
                        margin: 0
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    #root {
        &.front {
            .heading-02 {
                font-size: 24px
            }
            .desktop-table {
                tr, td, th, tbody {
                    display: block
                }
                thead {
                    display: none
                }
                td:first-child {
                    &::before {
                        content: 'Name'
                    }
                }
                td:nth-child(2) {
                    &::before {
                        content: 'Start Date'
                    }
                }
                td:nth-child(3) {
                    &::before {
                        content: 'End Date'
                    }
                }
                td:nth-child(4) {
                    &::before {
                        content: 'Status'
                    }
                }
                td:nth-child(5) {
                    &::before {
                        content: 'Total'
                    }
                }
                td {
                    padding-left: 40%;
                    &::before {
                        position: absolute;
                        vertical-align: inherit;
                        width: 100%;
                        left: 10px;
                        padding-right: 50px
                    }
                }
            }
        }
    }
    .heading-section-pricing .heading-table-price {
        font-size: 24px;
        line-height: 32px
    }
    .btn-menu-filter {
        display: inline-block;
        vertical-align: top !important;
        font-size: 26px !important;
        cursor: pointer
    }
    body.nav-open-filter {
        overflow: hidden
    }
    .btn-filter-box-shop {
        display: inline-block
    }
    ion-icon.btn-menu-filterBack.md.hydrated {
        color: $White;
        font-size: 26px !important;
        position: absolute;
        right: 16px;
        margin: 0;
        display: block;
        cursor: pointer;
        z-index: 9
    }
    .nav-open-filter {
        .shop-page {
            .product-right-wrapper {
                left: 0
            }
        }
    }
    .shop-page .product-right-wrapper {
        position: fixed;
        padding-top: 48px;
        left: -320px;
        background-color: $Dark;
        overflow: auto;
        top: 80px;
        bottom: 0;
        width: 300px;
        z-index: 9;
        transition: all 0.3s ease-in-out
    }
    .mobile-orders {
        display: block;
        .mobile-table {
            background: $White;
            padding: 20px;
            margin-bottom: 20px;
            box-shadow: 0px 2px 3px #ccc;
            .action-bar {
                text-align: right
            }
            .form-group {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                .col-9.controls {
                    padding-left: 20px;
                    display: block
                }
            }
        }
    }
    .banner-box-home {
        flex-direction: column-reverse;
        .left-banner {
            .service-details {
                margin: 80px
            }
        }
    }
    .white.main-content {
        max-height: 100px;
        overflow: hidden
    }
    .heading-02 {
        font-size: 30px;
        line-height: 40px
    }
    #root.front {
        .profile-page {
            .profile-main-section {
                .left-menu-section {
                    .nav-list .menus{
                        width: 100%
                    }
                }
            }
        }
        .jumbo-container {
            max-width: 720px
        }
        .banner {
            .slider-items {
                .slider-inner {
                    height: 100%
                }
            }
        }
        .pricing-page {
            .pricing-header-background {
                width: 100%
            }
            .pricing-tble-grid {
                display: block;
                padding: 20px;
                width: 100%;
                .grid-Box {
                    margin: 70px 0px
                }
            }
            .header-inner {
                .image-right {
                    display: none
                }
            }
            .empty-subscriptions {
                background: #fff;
                border-radius: 4px;
                box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
                padding: 30px;
                margin-bottom: 30px;
                img{
                    opacity: 0.7;
                    margin: auto
                }
                button {
                    margin: 20px auto
                }
            }
        }
    }
    .form-group {
        flex-direction: column
    }
}
@media (max-width: 600px) {
    #root.front {
        .main-section {
            .car-adds-section {
                margin-top: -30px;
                .col-4 {
                    margin-bottom: 40px
                }
            }
        }
        .col-3, .col-9 {
            width: 100%;
            padding: 0
        }
        .main-section {
            .car-select-form {
                padding: 0 20px;
                .home-02_tab-section {
                    .tab-content {
                        .select-box-wrap {
                            flex-wrap: wrap;
                            display: block;
                            .items {
                                width: 100%;
                                .custom-select-box {
                                    padding: 10px;
                                    select {
                                        width: 100%;
                                        margin: 0;
                                        padding: 20px
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .our-working-process {
                .page-division-flex {
                    display: block;
                    .working-sec {
                        width: 100%;
                        .icon-box-wrapper {
                            padding: 75px 20px 0 20px;
                            text-align: center
                        }
                        .image-part {
                            padding: 40px 0 0
                        }
                    }
                    .left-working, .right-working {
                        padding: 0
                    }
                }
            }
            .our-service {
                .main-wrapper {
                    display: block;
                    .left-image-section, .right-content-section {
                        max-width: 100%;
                        padding: 0 15px;
                        .service-section-bottom {
                            padding: 45px 0 0 0;
                            .items-wrap {
                                display: block;
                                .home-02-our-service-items {
                                    width: 100%
                                }
                            }
                        }
                    }
                }
            }
        }
        .secure_Sec {
            .main-secure {
                display: block;
                .item {
                    max-width: 100%
                }
            }
        }
        .footer {
            .jumbo-container {
                .footer-box-wrapper {
                    padding-top: 20px;
                    display: block;
                    .col-4, .col-2, .col-3 {
                        max-width: 100%;
                        position: relative;
                        padding: 0 15px;
                        margin-bottom: 20px;
                        width: 100%;
                        margin-top: 20px;
                        .icon {
                            padding-right: 10px;
                            float: left
                        }
                    }
                }
            }
        }
        .product-page {
            .product-page-body {
                .product-page-wrapper {
                    .grid-list-wrapper {
                        .grid-list-column-item {
                            .main-btn-wrap {
                                .main-btn.black-border {
                                    min-width: auto
                                }
                            }
                        }
                    }
                }
            }
        }
        .productdetail-page {
            .half-part-img-content-sec {
                margin-bottom: 50px;
                display: block;
                .sec-wrapper {
                    width: 100%;
                    display: block;
                    margin: auto;
                    .image-section {
                        width: 100%;
                        img {
                            width: 100%
                        }
                    }
                    .content-section {
                        margin: 50px 0;
                        width: 100%;
                        flex: 1 1;
                        padding: 0 15px;
                        text-align: center
                    }
                }
            }
        }
        .product-page-wrapper {
            max-width: 100% !important;
            .grid-list-wrapper {
                .grid-list-column-item {
                    width: 100% !important;
                    padding: 10px !important;
                    .main-btn-wrap {
                        .main-btn.black-border {
                            min-width: auto !important
                        }
                    }
                }
            }
        }
        .contact-page {
            display: block
        }
        .blog-page {
            .blog-page-content {
                .wrapper {
                    .blog-single-item {
                        display: block;
                        width: 100%;
                        padding-left: 0px
                    }
                }
            }
        }
    }
}
@media (max-width: 576px) {
    #root.front {
        .jumbo-container {
            max-width: 540px
        }
    }
}