body {
  margin: 0;
}
#root.admin {
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url("../../images/background-3.png");
  background-color: $BackgroundColor;
  .disable-cursor {
    cursor: not-allowed !important;
  }
  .form-group {
    position: relative;
    margin-bottom: 10px;
    label {
      color: $Gray;
      font-weight: 500;
    }
  }
  .service-package-icon {
    font-size: 40px;
    color: #ddd;
    background-color: #f5f5f5;
    height: 30px;
    padding: 10px;
    width: 30px;
    border: 5px solid #ddd;
  }
  sup {
    color: $Primary;
    font-size: 22px;
    line-height: 0;
    vertical-align: middle;
    margin-left: 0;
  }
  .order-detail-h1 {
    display: flex !important;
  }
  .btn-order-detail {
    margin-left: 10px;
    align-self: center;
    ion-icon {
      padding-right: 10px;
      vertical-align: middle;
    }
  }
  .btn {
    cursor: pointer;
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    height: 40px;
    border-radius: 6px;
    border: 2px solid $Primary;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    padding: 0 20px !important;
    min-width: 120px;
    &.btn-outline {
      background-color: $White !important;
      color: $Primary !important;
    }
    &.btn-orange {
      background-color: $Primary;
      color: $White !important;
    }
    &.btn-small {
      max-width: 180px;
      line-height: 30px;
      height: 34px;
    }
    &.btn-big {
      width: 200px;
      height: 50px !important;
      line-height: 48px !important;
      border-radius: 10px !important;
      font-size: 16px !important;
    }
    &.btn-theme {
      background-color: $Primary;
      color: $White !important;
      transition: 0.1s all linear;
      &:hover {
        background-color: $White;
        color: $Primary !important;
      }
    }
  }

  .btn-width {
    max-width: 240px;
    line-height: 30px;
    height: 34px;
  }
  .sidebar {
    width: 310px;
    transition: all 0.3s ease-in-out;
    background-color: $Dark;
    .logo-link {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      height: 80px;
      padding: 5px 10px;
      display: flex;
      box-shadow: 0 1px 2px #cccccc;
      img {
        max-height: 70px;
        width: auto;
        display: block;
        margin: auto;
      }
    }
    nav {
      &.sidebar-nav {
        max-height: calc(100vh - 140px);
        position: relative;
        z-index: 9;
        box-shadow: $DarkShadow;
        ul {
          &.nav {
            padding-top: 15px;
            padding-left: 0;
            overflow: auto;
            height: calc(100vh - 120px);
            > li {
              > button,
              > a {
                > span.side-bar-title {
                  display: none;
                }
              }
            }
            .side-bar-title {
              line-height: 25px;
              text-align: left;
              width: calc(100% - 40px);
            }
            li {
              font-size: 18px;
              transition: all 0.3s ease-in-out;
              position: relative;
              list-style: none;
              .submenus {
                display: flex !important;
                justify-content: space-between;
              }
              a,
              button {
                display: block;
                margin: 0;
                transition: all 0.3s ease-in-out;
                border-radius: 10px;
                background: transparent;
                align-items: center;
                width: 100%;
                text-align: left;
                height: 60px;
                font-size: 18px;
                padding: 10px 15px;
                border-top-left-radius: 1.25rem;
                border-bottom-left-radius: 1.25rem;
                transition: all 0.5s;
                position: relative;
                border: none;
                line-height: 50px;
                img {
                  max-width: 20px;
                  max-height: 20px;
                  margin: auto;
                }
                ion-icon {
                  position: relative;
                  height: 20px;
                  width: 20px;
                  -webkit-transition: all 0.5s;
                  -ms-transition: all 0.5s;
                  transition: all 0.5s;
                  align-self: center;
                  margin: auto;
                }
                span {
                  color: #7e7e7e;
                  font-size: 16px;
                }
                .icon-box-sidebar {
                  height: 40px;
                  min-width: 40px;
                  display: inline-block;
                  margin-right: 15px;
                  text-align: center;
                  border-radius: 4px;
                }
              }
              &.active {
                background-image: url("../../images/image.png");
                .icon-box-sidebar {
                  color: $White;
                  background: $Primary;
                  display: flex;
                  ion-icon {
                    color: $White;
                  }
                }
                .icon-box-sidebar {
                  margin-left: 15px;
                }
                > button,
                > a {
                  padding: 10px;
                }
                span {
                  color: #7e7e7e;
                }
              }
              .sub-nav-bar {
                position: fixed;
                top: 0;
                bottom: 0;
                background-color: #fff;
                left: 91px;
                width: 200px;
                ul {
                  &.sub-nav {
                    max-width: 0;
                    max-height: 0;
                    display: block;
                    overflow: hidden;
                    margin-left: 0;
                    transition: all 0.3s ease-in-out;
                    position: relative;
                    top: 0;
                    z-index: 1111;
                    background-color: #fff;
                    transition: -webkit-transform 0.3s;
                    transition: transform 0.3s;
                    transition: transform 0.3s, -webkit-transform 0.3s;
                    height: 100%;
                    left: auto;
                    right: 0;
                    -webkit-transform: translateX(100%);
                    transform: translateX(100%);
                    .icon-box-sidebar {
                      width: 40px;
                      height: 40px;
                      line-height: 40px;
                      border-radius: 10px;
                    }
                    li {
                      a,
                      button {
                        height: 60px;
                        line-height: 40px;
                      }
                      .icon-box-sidebar {
                        background: $White;
                        box-shadow: 0px 0px 10px 3px #88838e33;
                        ion-icon {
                          color: $Primary;
                        }
                      }
                    }
                    span {
                      font-size: 14px;
                    }
                  }
                }
              }
              &.active {
                .sub-nav-bar {
                  
                  ul {
                    &.sub-nav {
                      max-width: 500px;
                      max-height: 100vh;
                      -webkit-transform: translateX(0);
                      transform: translateX(0);
                      background: #e0e6f5;
                      overflow-y: scroll;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .container {
    transition: all 0.3s ease-in-out;
    width: calc(100vw - 310px);
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    padding: 0;
    position: relative;
    z-index: 0;
    &.admindashboard {
      width: calc(100vw - 80px);
      margin-left: unset;
      .admin-dashboard-heading {
        display: flex;
        .select-filter {
          min-width: 250px;
          ul {
            display: flex;
            li {
              display: flex;
              margin-right: 24px;
              label {
                width: 100px;
                text-align: right;
                padding-right: 10px;
                line-height: 40px;
              }
            }
          }
        }
      }
    }
    .business {
      position: relative;
      width: 100%;
      height: 240px;
      background-color: $White;
      z-index: 9999;
      box-shadow: 0 1px 2px $ShadowColor;
      border-radius: 4px;
    }
    .call_request_list {
      position: relative;
      width: 100%;
      height: 100px;
      background-color: $White;
      z-index: 9999;
      box-shadow: 0 1px 2px $ShadowColor;
      border-radius: 4px;
    }
    .sec_business {
      width: 100%;
      height: 590px;
      background-color: $White;
      z-index: 9999;
      box-shadow: 0 1px 2px $ShadowColor;
      border-radius: 4px;
      margin-top: 20px;
    }
    .inner_business {
      width: 46%;
      height: 50px;
      background-color: $White;
      display: flex;
      z-index: 9999;
      box-shadow: 2px 3px 4px $ShadowColor;
      border-radius: 6px;
      border-color: black;
      padding-top: 0px;
      // border-style: solid;
      margin-left: 40px;
      float: left;
    }
    .inner_hold_request {
      width: 20%;
      height: 50px;
      background-color: $White;
      display: flex;
      z-index: 9999;
      box-shadow: 2px 3px 4px $ShadowColor;
      border-radius: 6px;
      border-color: black;
      padding-top: 0px;
      // border-style: solid;
      margin-left: 40px;
      float: left;
    }
    .inner_sec_business {
      width: 95%;
      height: 50px;
      background-color: $White;
      display: flex;
      z-index: 9999;
      box-shadow: 2px 3px 4px $ShadowColor;
      border-radius: 6px;
      border-color: black;
      padding-top: 0px;
      // border-style: solid;
      margin-left: 40px;
      float: left;
    }
    .head_margin {
      margin-left: 60px;
    }
    .inner_head_margin {
      margin-left: 40px;
    }
    .inner_defultNo_margin {
      margin-left: 60%;
    }
    .inner_detail_margin {
      padding-left: 10px;
    }
    .header {
      height: 80px;
      background-color: $White;
      display: flex;
      z-index: 9999;
      box-shadow: 0 1px 2px $ShadowColor;
      border-radius: 4px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      .nav-drawer {
        margin: auto 0;
        text-align: center;
        .nav-btn {
          background-color: transparent;
          border: 0;
          padding: 9px !important;
          min-width: 50px;
          text-align: center;
          height: 50px;
          margin: 15px;
          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;
          margin: auto 0;
          ion-icon {
            font-size: 32px !important;
            color: $AshGray;
            margin: auto !important;
          }
          &:hover {
            .line {
              &:nth-child(1) {
                width: 26px;
              }
              &:nth-child(2) {
                width: 26px;
              }
              &:nth-child(3) {
                width: 26px;
              }
            }
          }
          .line {
            background: $Primary !important;
            display: block;
            height: 3px;
            border-radius: 3px;
            margin-top: 6px;
            margin-bottom: 6px;
            margin-left: auto;
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
            &:nth-child(1) {
              width: 20px;
            }
            &:nth-child(2) {
              width: 26px;
            }
            &:nth-child(3) {
              width: 22px;
            }
          }
        }
      }
      nav {
        position: relative;
        margin-left: auto;
        ul {
          display: flex;
          align-items: center;
          height: 80px;
          box-shadow: none !important;
          overflow: visible !important;
          right: 0;
          li.nav-item {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            .input-group.search-area.d-lg-inline-flex.d-none {
              width: 300px;
              display: inline-flex;
            }
            input.form-control {
              background: #efefef !important;
              border-width: 0px;
              padding-left: 20px;
              min-height: 55px;
              border-radius: 0;
              border-top-left-radius: 60px !important;
              border-bottom-left-radius: 60px !important;
              border: none !important;
            }
            .input-group-append {
              display: flex;
            }
            span.input-group-text {
              background: #efefef;
              border-width: 0px;
              border-top-right-radius: 60px;
              border-bottom-right-radius: 60px;
              padding-left: 0;
              padding-right: 20px;
            }
            a {
              padding: 0;
              height: 55px;
            }
            ion-icon {
              font-size: 25px;
              margin: auto;
            }
            &:not(:first-child) {
              padding-left: 20px;
            }
          }
          li.notification_dropdown {
            a {
              padding: 12px;
              position: relative;
              span {
                position: absolute;
                font-size: 14px;
                border-radius: 40px;
                right: 0px;
                top: 0px;
                font-weight: normal;
                height: 26px;
                width: 26px;
                line-height: 26px;
                text-align: center;
                padding: 0px;
                background-color: $Cream;
              }
              ion-icon {
                font-size: 28px;
                margin: auto;
              }
            }
            .dropdown-menu-right {
              min-width: 310px;
              padding: 0rem 0 1rem;
              top: 100%;
              position: absolute;
              border-width: 0;
              box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
              right: 0;
              left: auto;
              color: #7e7e7e;
              text-align: left;
              list-style: none;
              background-color: #fff;
              background-clip: padding-box;
              .all-notification {
                display: block;
                padding: 15px 30px 0;
                text-align: center;
                border-top: 1px solid #f4f5f9;
                font-size: 18px;
                ion-icon {
                  font-size: 16px;
                  vertical-align: middle;
                  margin-left: 10px;
                  display: inline-block;
                }
              }
              ul.timeline {
                display: block !important;
                width: 100%;
                height: 100%;
                position: relative;
                .timeline-panel {
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eaeaea;
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  .media {
                    width: 45px;
                    height: 45px;
                    background: #eee;
                    border-radius: 1.25rem;
                    overflow: hidden;
                    font-size: 18px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    align-self: start;
                    margin-right: 8px;
                    img {
                      border-radius: 3px;
                    }
                  }
                  .media-body {
                    flex: 1;
                    line-height: 1.2;
                    h6 {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 1.2;
                      margin-bottom: 4px;
                    }
                    .small {
                      font-size: 12px;
                    }
                  }
                }
              }
              .height380 {
                height: 380px;
                overflow-y: scroll;
                padding: 16px;
              }
            }
          }
        }
        .profile-box {
          user-select: none;
          cursor: pointer;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.25);
          display: flex;
          height: 80px;
          padding: 0 0 0 32px;
          max-width: 200px;
          width: 100%;
          ion-icon {
            font-size: 24px;
            color: #181818;
            height: 55px;
            line-height: 50px;
            width: 55px;
          }
          span {
            font-size: 16px;
            color: $Dark;
            height: 50px;
            margin: 15px 0;
            line-height: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 20px;
          }
          &:hover {
            ion-icon,
            span {
              color: $Primary;
            }
          }
        }
        .user-dashboard-menus {
          top: 80px;
          width: 200px;
          z-index: 9;
          display: block;
        }
        ul {
          background-color: $White;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          overflow: hidden;
          position: absolute;
          box-shadow: 0 2px 3px $ShadowColor;
          li {
            list-style: none;
            margin: 0;
            padding: 0;
            a,
            i {
              color: $Primary;
              font-style: normal;
              cursor: pointer;
              display: flex;
              line-height: 40px;
              padding: 0 15px;
              span {
                display: block;
                font-size: 14px;
              }
              ion-icon {
                font-size: 14px;
                margin: 12px 10px 0 0;
                display: block;
              }
            }
          }
        }
      }
    }
    .content {
      height: 100vh;
      overflow: auto;
      background-color: transparent;
      padding: 90px 0 0;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 400;
        display: block;
        position: relative;
      }
      h1 {
        &.title {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 40px;
          overflow: visible;
          > a,
          > button {
            margin-left: 20px;
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 50px;
            bottom: -8px;
            height: 2px;
            background-color: $Primary;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 40px;
            bottom: -15px;
            height: 2px;
            background-color: $AshGray;
          }
        }
      }
      ul.media-box {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        &.banner-box {
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }
        li {
          background-color: $LightGray;
          display: inline-flex;
          padding: 10px;
          position: relative;
          max-width: 300px;
          flex-direction: column;
          p {
            text-align: center;
            padding: 10px 0 0;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            margin: auto;
            display: block;
            box-shadow: 0 1px 2px $Dark;
          }
          &.selected {
            border: 3px solid $Success;
            padding: 7px;
          }
          ion-icon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 10px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &.trash {
              color: $Error;
              right: 10px;
            }
            &.preview {
              color: $AshGray;
              left: 10px;
            }
            &.select {
              color: $Success;
              left: 10px;
            }
          }
          &:hover {
            ion-icon {
              opacity: 1;
            }
          }
          img {
            display: block;
          }
        }
      }
      .grid-area {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        .section {
          padding: 20px;
          background-color: $White;
          border-radius: 10px;
          height: max-content;
          h4.head {
            margin: -20px -20px 20px;
            border-bottom: 1px solid $LightGray;
            padding: 0 40px 0 20px;
            position: relative;
            font-size: 16px;
            line-height: 44px;
            font-weight: 300;
            cursor: pointer;
            ion-icon {
              position: absolute;
              right: 0;
              width: 24px;
              height: 44px;
              font-size: 10px;
              color: #999;
              margin: 0 9px;
            }
          }
          .body {
            overflow: hidden;
            margin: -20px;
            padding: 20px;
            transition: all 0.3s ease-in-out;
          }
          &.closed {
            .body {
              max-height: 0;
              padding: 0 20px;
            }
          }
        }
      }
      .grid-area-admin-section {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        .section {
          padding: 20px;
          background-color: $White;
          border-radius: 10px;
          height: max-content;
          h4.head {
            margin: -20px -20px 20px;
            border-bottom: 1px solid $LightGray;
            padding: 0 40px 0 20px;
            position: relative;
            font-size: 16px;
            line-height: 44px;
            font-weight: 300;
            cursor: pointer;
            ion-icon {
              position: absolute;
              right: 0;
              width: 24px;
              height: 44px;
              font-size: 10px;
              color: #999;
              margin: 0 9px;
            }
          }
          .body {
            overflow: hidden;
            margin: -20px;
            padding: 20px;
            transition: all 0.3s ease-in-out;
            display: flex;
            .icon-section {
              flex: 1;
              text-align: left;
              align-self: center;
              ion-icon {
                font-size: 40px;
                color: $Dark;
              }
              .pending-orders {
                color: #ffba00;
              }
              .order-complete {
                color: #7ad03a;
              }
              .wallet {
                color: #0073aa;
              }
            }
            .description {
              flex: 3;
              .title {
                font-size: 14px;
                color: $Dark;
              }
              .description-stats {
                color: $Dark;
                font-size: 22px;
              }
            }
          }
          &.closed {
            .body {
              max-height: 0;
              padding: 0 20px;
            }
          }
        }
      }
      .orders-frame {
        .inside-box {
          width: calc(50% - 10px);
          background-color: $White;
          border-radius: 10px;
          height: max-content;
          margin: 20px 0;
          @media (max-width: 1024px) {
            width: 100%;
          }
          .inside {
            li {
              width: 50%;
              display: inline-block;
              padding: 20px;
              border-right: 1px solid $BorderColor;
              border-bottom: 1px solid $BorderColor;
              @media (max-width: 991px) {
                width: 50%;
              }
              @media (max-width: 768px) {
                width: 50%;
              }
              @media (max-width: 500px) {
                width: 100%;
                border-bottom: 1px solid $BorderColor;
                border-right: none;
                &:nth-child(3) {
                  border-bottom: 1px solid $BorderColor !important;
                }
              }
              &:last-child {
                border-bottom: none;
                border-right: none;
              }
              &:nth-child(3) {
                border-bottom: none;
              }
              &:nth-child(2) {
                border-right: none;
              }
              &:hover {
                a {
                  color: $Primary;
                }
                .name-title {
                  strong {
                    color: $Primary;
                  }
                }
              }
              color: $Dark;
              cursor: pointer;
              .icon-box {
                flex: 1;
              }
              ion-icon {
                align-self: center;
                font-size: 40px;
              }
              ion-icon.user-icon {
                color: $Success;
              }
              ion-icon.on-hold-orders {
                color: #999;
              }
              ion-icon.low-in-stock {
                color: #ffba00;
              }
              ion-icon.out-of-stock {
                color: $Error;
              }
              .name-title {
                flex: 3;
                strong {
                  font-size: 20px;
                  line-height: 1.2em;
                  font-weight: 400;
                  display: block;
                  color: $Dark;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      height: 50px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 50px;
      color: $AshGray;
      padding: 0 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
      background-color: $White;
    }
  }
  .flex-box {
    display: flex;
  }
  .form-box {
    flex: 1;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    padding: 40px;
    max-width: 300px;
    box-shadow: 0 1px 4px $AshGray;
  }
  .btn-outline {
    background-color: $White;
    color: $Primary;
    border: 1px solid $Primary;
  }
  .flex-box {
    flex: 1;
  }
  button {
    cursor: pointer;
    user-select: none;
    &:focus {
      outline: none;
      border: none;
    }
  }
  ::placeholder {
    color: $AshGray;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  .form-title {
    font-size: 12px;
    margin-bottom: 20px;
  }
  ion-icon {
    font-size: 16px;
    visibility: inherit;
  }
  .header-container {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
  }
  table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    background-color: $White;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 2px $ShadowColor;
    thead {
      text-align: left;
      tr th {
        background-color: $TableBackground;
        padding: 10px 15px;
        font-weight: 700;
      }
    }
    tbody {
      tr td {
        padding: 10px 15px;
        font-weight: 400;
        &.small-col {
          height: 40px;
        }
      }
    }
  }

  table tbody tr:nth-child(odd) td,
  table tbody tr:nth-child(odd) th {
    background-color: $White;
  }
  table tbody tr td,
  table tbody tr th,
  table thead tr td,
  table thead tr th {
    padding: 10px 15px;
    border: 1px solid #ccc;
    text-align: left;
    min-width: auto;
    position: relative;
    .action-bar {
      display: none;
      position: absolute;
      bottom: 0px;
      left: 20px;
      .action {
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        margin-right: 10px;
        cursor: pointer;
        color: $Dark;
        &:hover {
          color: $Primary;
        }
        ion-icon {
          font-size: 20px;
        }
      }
    }
    &:hover {
      .action-bar {
        display: block;
      }
    }
    .action-bar-page {
      position: absolute;
      bottom: 0px;
      // text-align: center;
      left: 20px;
      .action-page {
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        margin-right: 10px;
        cursor: pointer;
        color: $Dark;
        &:hover {
          color: $Primary;
        }
        ion-icon {
          font-size: 20px;
        }
      }
    }
  }
  table tbody tr:nth-child(even) td,
  table tbody tr:nth-child(even) th {
    background-color: $TableBackground;
  }
  ul.pagination {
    list-style: none;
    margin: 0 auto 20px 0;
    padding: 0;
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    li {
      min-width: 42px;
      user-select: none;
      text-align: center;
      background-color: $Cream;
      line-height: 40px;
      cursor: pointer;
      color: #de7c00;
      border-right: 1px solid #ddd;
      &.active {
        background-color: $TableBackground;
        color: #1a2d38;
        cursor: not-allowed;
      }
    }
    .next.inactive,
    .prev.inactive {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .next {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .prev {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 210px;
    }
    .next,
    .prev {
      padding: 0 13px;
      background-color: $Primary;
      color: $Cream;
    }
  }
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="phone"],
  input[type="text"],
  select,
  textarea {
    background-color: $White;
    border: 0;
    border-radius: 4px;
    line-height: 40px;
    height: 40px;
    display: block;
    width: 100%;
    font-size: 14px;
    color: $Dark;
    padding: 0 20px;
    border: 1px solid $BorderColor;
    &:focus {
      outline: none;
    }
  }
  textarea {
    min-height: 200px;
    line-height: 24px;
    padding: 10px 20px;
  }
  .chatbotqueries {
    p {
      font-weight: 300;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    display: block;
    position: relative;
    line-height: 48px;
    margin-bottom: 10px;
  }
  .errorpage {
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    a {
      text-decoration: none;
      background-color: $Dark;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 1px 2px $ShadowColor;
    }
    h1 {
      font-weight: normal;
      text-shadow: 0 2px 5px #aaa;
      font-size: 25vw;
      color: $White;
      line-height: 1.2;
    }
    .oops-text {
      font-weight: 600;
      font-size: 40px;
    }
  }
  .body-background {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    overflow: auto;
  }
  span.tox-statusbar__branding {
    display: none;
  }
  .react-datepicker {
    width: 100%;
    height: 40px;
    border: 1px solid $BorderColor;
    .react-datepicker__wrapper {
      border: none;
      input {
        display: inline-block;
        width: auto !important;
        padding: 0 10px !important;
        text-align: center;
        height: 30px;
        margin-top: 5px;
        border-radius: 0;
        background-color: transparent;
      }
    }
  }
}
body {
  #root.admin {
    .sidebar {
      max-width: 90px;
      width: 100%;
      background-color: $White;
      height: 100%;
      transition: all 0.3s ease-in-out;
      margin-left: 0;
      position: relative;
      border-radius: 4px;
      z-index: 9;
      &:after {
        background-image: url("../../images/business-list-expended-wave-min.png");
        position: absolute;
        content: "";
        width: 100%;
        height: 150px;
        background-repeat: no-repeat;
        background-position: center bottom;
        bottom: 0;
        background-size: contain;
      }
      li.active > a {
        background: $Black;
        color: $White;
        position: relative;
      }
      ul.sub-nav {
        > li.active > a {
          &::after {
            content: ">";
            position: absolute;
            right: 25px;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 21px;
            border-radius: 20px;
            background-color: rgba(255, 255, 255, 0.15);
            justify-content: center;
            top: 21px;
          }
        }
      }
    }
  }
  &.closed {
    #root.admin {
      .sidebar {
        width: 90px;
        nav.sidebar-nav {
          ul.nav {
            li {
              padding: 0;
              transition: all 0.3s ease-in-out;
              button,
              a {
                margin: 0;
              }
              .side-bar-title {
                display: none;
              }
            }
          }
        }
      }
      .container {
        width: calc(100vw - 80px);
      }
    }
  }
  @media (max-width: 768px) {
    #root.admin {
      .sidebar {
        text-align: left;
        margin-left: -240px;
      }
      .sub-nav-bar {
        display: none;
      }
      .container {
        width: 100%;
      }
    }
    &.closed {
      #root.admin {
        .sidebar {
          text-align: left;
          margin-left: 0px;
          width: 310px;
          nav.sidebar-nav {
            ul.nav {
              li {
                a {
                  span {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        .container {
          width: calc(100vw - 240px);
        }
      }
    }
  }
}
.payment-success {
  padding: 50px 0;
}
#root {
  &.admin {
    .shop_table_responsive {
      overflow: visible;
    }
    .may-be-interested {
      h2 {
        margin: 0 0 8px 0;
      }
    }
    tr:nth-child(2n + 1) {
      background: $White;
    }
  }
}
#chat-modal,
#category-modal,
#brand-modal,
#faq-modal {
  small.msg.text-danger {
    display: block;
    display: none;
  }
  .faq-modal-header {
    padding: 20px;
  }
  label {
    position: absolute;
    top: -10px;
    background-color: #fcfaf1;
    font-size: 11px;
    line-height: 17px;
    padding: 0 7px;
    border: 1px solid #1a2d38;
  }
  .form-group {
    position: relative;
    margin-bottom: 40px;
  }
  .chat-modal-header,
  .category-modal-header,
  .brand-modal-header {
    padding: 20px;
  }
  .heading {
    margin-bottom: 20px;
  }
  .modal-header {
    border-bottom: none;
  }
}
.filter-box {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  @media (max-width: 768px) {
    display: block;
  }
  .filters {
    align-self: center;
    span,
    a {
      padding: 0 15px 0 0;
      margin-right: 15px;
      color: $Dark;
      line-height: 1;
      border-right: 1px solid $Dark;
      &.active {
        color: $Primary;
      }
      &:last-child {
        border: none;
      }
    }
  }
  input[type="text"] {
    border-radius: 4px !important;
    border: 1px solid $Dark !important;
    height: 40px !important;
    background-color: $White !important;
  }
  .search-box {
    display: flex;
    ion-icon {
      padding: 8px;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      background: $White;
    }
    button {
      height: 40px;
    }
  }
}
.icon-check {
  font-size: 22px !important;
  vertical-align: sub;
  margin-right: 10px;
}
.isFeatured {
  user-select: none;
}
div#trash-modal .modal-body {
  text-align: center;
  ion-icon {
    font-size: 60px !important;
    margin: auto;
    display: block;
    color: $Primary;
  }
  .btn-box-trash {
    justify-content: space-evenly;
    margin: 20px 0;
  }
}
.complaint-modal-header {
  max-height: 600px;
  overflow-y: scroll;
  .heading {
    font-size: 20px;
  }
}
.form-group.box {
  padding: 20px;
  margin-bottom: 20px !important;
  label {
    margin-bottom: 10px;
  }
}
#root.admin div#priventive-checklist-modal {
  table {
    border-collapse: collapse;
    color: #3e3e3e;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    tbody tr td {
      height: 40px;
    }
    th,
    td {
      border: 1px solid #e9e9e9 !important;
    }
  }
  tr.queryListStatus th {
    background-color: #fafafa;
    padding: 7px 15px !important;
    font-weight: bold;
  }
  td.query-list-icon-Box {
    background: transparent !important;
  }
  h3 {
    font-weight: 600;
  }
}
.order-type {
  padding: 10px;
  position: relative;
  ion-icon {
    vertical-align: middle;
    color: $Primary;
    font-size: 18px !important;
    margin-right: 5px;
    cursor: pointer;
  }
  .hammer-outline {
    &:hover {
      > .hover-action {
        display: block;
      }
    }
  }
  .cube-outline {
    &:hover {
      > .hover-action {
        display: block;
      }
    }
  }
  .ribbon-outline {
    &:hover {
      > .hover-action {
        display: block;
      }
    }
  }
  .list-circle-outline {
    &:hover {
      > .hover-action {
        display: block;
      }
    }
  }
  .hover-action {
    display: none;
    position: absolute;
    background: #000;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 2px 10px;
    z-index: 9;
    top: 10px;
    width: 150px;
    text-align: center;
    left: 112px;
  }
}
.check-all {
  width: 40px;
  ion-icon {
    vertical-align: middle;
    font-size: 22px !important;
    cursor: pointer;
  }
}
.datePicker-filter {
  margin-left: 10px;
}
.date-filter {
  span {
    margin-right: 20px;
    display: inline-block;
  }
}
input.datePicker-filter.react-datepicker-ignore-onclickoutside {
  background-color: $White;
}
#product-status-modal {
  .track {
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
    .step {
      flex-grow: 1;
      width: 25%;
      margin-top: -18px;
      text-align: center;
      position: relative;
      &.active {
        &::before {
          background: #ff5722;
        }
        .icon {
          background: #ee5435;
          color: #fff;
        }
        .text {
          font-weight: 400;
          color: #000;
        }
      }
      &::before {
        height: 7px;
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        top: 18px;
      }
    }
    .text {
      display: block;
      margin-top: 7px;
    }
    .icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      position: relative;
      border-radius: 100%;
      background: #ddd;
      ion-icon {
        font-size: 26px !important;
        padding-top: 7px;
      }
    }
  }
  .trackForm {
    position: relative;
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    input {
      max-width: 300px;
      width: 100%;
      display: block;
      margin: auto;
    }
    .inputBoxTrackingOrder {
      margin-bottom: 20px;
    }
  }
}
.column {
  flex-direction: column !important;
}
.chart-dashboard {
  .chart-row {
    margin-bottom: 24px;
  }
  .chart-box {
    display: flex;
    .chart-component {
      flex: 1;
      background: #fff;
      padding: 16px;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.counting {
        text-align: center;
        display: flex;
        align-items: center;
      }
      .heading-box {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
#root.admin {
  .content {
    .left {
      .box {
        .body {
          .campaign-base {
            li {
              display: inline-block;
              .campaign-channel-box {
                width: 100%;
                padding: 20px;
                border: 1px solid #ededed;
                border-radius: 15px;
                display: inline-block;
                vertical-align: top;
                max-width: 320px;
                margin: 0 20px 20px 0;
                min-height: 270px;
                cursor: pointer;
                position: relative;
                .campaign-title {
                  display: flex;
                  margin-bottom: 10px;
                  ion-icon {
                    background-color: $Primary;
                    color: $White;
                    width: 24px;
                    height: 24px;
                    font-size: 28px !important;
                    border-radius: 50%;
                    text-align: center;
                    padding: 12px;
                  }
                  span {
                    margin: auto auto auto 10px;
                  }
                }
                h4 {
                  line-height: 28px;
                  margin-bottom: 16px;
                  font-weight: 700;
                }
                span.selected-campaign {
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  ion-icon {
                    font-size: 26px;
                    color: $Success;
                  }
                }
              }
            }
          }
          .btn-box-sender-id {
            justify-content: flex-end;
            display: flex;
            button {
              margin-left: 20px;
            }
          }
          &.flex-box-audience {
            display: flex;
            align-items: center;
          }
          .img-icons {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .key-images {
    width: 100px;
    height: 100px;
  }
}
.open-submenus {
  padding-top: 93px;
  position: absolute;
  top: 0;
  background: #fff;
  bottom: 0;
  right: -160px;
  width: 160px;
  padding: 120px 20px 0px 20px;
  li {
    padding: 10px;
    list-style: none;
    border-radius: 4px;
    margin-bottom: 10px;
    a {
      background-color: transparent !important;
    }
    &.active {
      background: $Primary;
      color: $White;
      a {
        color: $White;
      }
    }
  }
}
.CTable {
  width: 65%;
  margin-left: 30px;
}
.invoice-tags {
  padding: 20px;
  &.cashback-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-area {
      margin: 5px;
      width: calc(100% - 180px);
      overflow: scroll;
    }
    .add-business {
      width: 250px;
    }
  }
  ul {
    display: flex;
    li {
      border-radius: 26px;
      box-shadow: $DarkShadow;
      padding: 2px 20px;
      font-size: 12px;
      margin-right: 16px;
      background-color: $White;
      cursor: pointer;
      list-style: none;
      text-align: center;
      align-items: center;
      white-space: nowrap;
      img {
        width: 20px;
        height: 20px;
        margin-top: 3px;
        margin-right: 10px;
      }
      &.active {
        background-color: $Primary;
        color: $White;
      }
    }
  }
}
.actions-buttons {
  display: flex;
  list-style: none;
  li {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}
.actions-buttons-businessList {
  display: flex;
  list-style: none;
  li {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
}
.defaulter {
  ion-icon {
    font-size: 30px !important;
  }
  .defaulter-list {
    background-color: $White;
    box-shadow: $DarkShadow;
    .actions-buttons {
      display: flex;
      list-style: none;
      li {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .search-filters {
    padding: 20px 20px 0;
    display: flex;
    justify-content: space-between;
    .searching-field {
      width: 100%;
      .input-filters {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        input {
          height: 34px !important;
        }
        [class$="control"] {
          height: 34px !important;
          min-height: 34px !important;
          [class$="ValueContainer"] {
            height: 30px !important;
          }
        }
      }
      ul {
        display: grid;
        list-style: none;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-gap: 10px 20px;
        width: calc(100% - 250px);
        li {
          label {
            margin-bottom: 0px;
            display: block;
            font-size: 10px;
          }
          > input,
          .css-yk16xz-control {
            border: 1px solid #5380be !important;
          }
        }
      }
    }
  }
}
.react-datepicker__input-container input {
  border: 1px solid #5380be !important;
}
.wallet-setting-list {
  .input {
    width: 100%;
    height: 40px;
    border: 0;
    max-width: 180px;
    line-height: 20px;
    background-color: $White;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: $Dark;
    border-radius: 10px;
    font-size: 14px;
    padding: 0 20px;
    border: 1px solid $BorderColor;
    box-shadow: 1px 2px $BorderColor;
    &:focus {
      outline: none;
    }
    &[disabled],
    [readonly] {
      background-color: $BorderColor;
    }
  }
}
.more-document-box {
  display: flex;
  .more-document-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    label {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
      margin-right: 10px;
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.defaulter-list-modal {
  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  label {
    color: rgba(0, 0, 0, 0.5);
  }
  textarea {
    min-height: 80px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $Primary;
    padding: 10px;
  }
  .status-details {
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      width: 50%;
    }
    .left {
      margin-right: 10px;
      p {
        border-radius: 4px;
        background-color: $AshGray;
        border: 1px solid $Primary;
        padding: 5px 10px;
      }
    }
  }
}
p.tooltip-admin-sidebar {
  position: absolute;
  right: 0;
  width: 200px;
  display: none;
  left: 75px;
  top: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  z-index: 9;
}
p.tooltip-admin-sidebar:before {
  position: absolute;
  content: "";
  border-top: 10px solid red;
  border: 10px solid white;
  border-right: 10px solid black;
  left: -16px;
  bottom: 4px;
}
#root.admin .sidebar nav.sidebar-nav ul.sub-nav li:hover p {
  display: none;
}
#root.admin .sidebar nav.sidebar-nav ul.nav > li:hover > p {
  display: block;
}
img.no-data-found-img {
  max-width: 600px !important;
  margin: auto;
  width: 100%;
  margin-top: 50px;
}
.process-business-registration {
  .form-box-mobile {
    [class$="container"] input {
      width: 50% !important;
      box-shadow: none !important;
    }
    .react-datepicker-wrapper {
      input {
        width: 100% !important;
      }
    }
  }
}
.addbusiness-cat {
  input {
    max-width: 180px;
  }
  .actions-icon {
    display: flex;
    align-items: center;
    margin-left: 20px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
.business-category {
  .heading-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-box-mobile {
    overflow: visible;
    [class$="container"] input {
      width: 50% !important;
      box-shadow: none !important;
    }
  }
}
.active-item-name {
  background: $Primary;
  margin: 13px;
  padding: 10px;
  border-radius: 4px;
  color: $White;
  font-size: 11px;
  text-align: center;
}
#root.admin .member-ship-table-new {
  table tbody tr:nth-child(even) th,
  table tbody tr:nth-child(even) td {
    background-color: transparent;
  }
  table tbody tr td {
    text-align: center !important;
  }
  .heading-td {
    background-color: $BorderColor !important;
  }
  table {
    margin-bottom: 0;
    tbody tr {
      th,
      td {
        border: 1px solid #ddd;
      }
    }
  }
}

.table-container {
  height: calc(100vh - 310px);
  &.full-table {
    height: calc(100vh - 210px);
  }
  overflow: scroll;
  table {
    margin-bottom: 0 !important;
  }
}

li {
  list-style: none;
}
.legal-info.legal-info-checbox {
  input {
    width: 20px;
    margin-right: 10px;
  }
  label {
    display: flex;
    align-items: center;
  }
}
.guest-audience-box {
  .guest-audience {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  textarea {
    min-height: 140px;
    margin-top: 10px;
  }
}
.admin-user-role-search {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  label {
    width: auto;
    margin-right: 10px;
  }
  input {
    width: auto !important;
  }
}
.suggestions {
  overflow-y: auto;
  width: 106% !important;
  height: 46%;
}
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

h2 {
  margin-top: 0;
}

.form-field {
  margin-bottom: 15px;
}

.label {
  display: block;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.value {
  margin-left: 10px;
  color: #555;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}
.cols-1 {
  margin-bottom: 15px;
  label {
    font-size: 14px;
    color: #aeaeae;
    &block {
      display: block;
    }
  }
  > * {
    font-size: 100%;
    .label {
      font-weight: 600;
      .block {
        display: block;
      }
    }

    input {
      height: 40px;
      margin: 10px 0;
      padding: 10px 20px;
      background-color: #f0f3f7;
      border: 1px solid #5380be;
      line-height: 40px;
      border-radius: 4px;
      width: 100%;
    }
  }
}
.cols-2 {
  display: flex;
  gap: 16px;
  margin-bottom: 15px;
  > * {
    flex-basis: 50%;
    flex-basis: calc(50% - 8px);
    font-size: 100%;
    .label {
      font-weight: 600;
      .block {
        display: block;
      }
    }
    label {
      font-size: 14px;
      color: #aeaeae;
      &block {
        display: block;
      }
    }
    input {
      height: 40px;
      margin: 10px 0;
      padding: 10px 20px;
      background-color: #f0f3f7;
      border: 1px solid #5380be;
      line-height: 40px;
      border-radius: 4px;
      width: 100%;
    }
  }
  > p {
    padding: 0px;
    margin: 0px 0 0px 0;
    .label {
      font-weight: 600;
    }
  }
  > .label {
    font-weight: 600;
  }
  > .right {
    text-align: right;
  }
  > .center {
    text-align: center;
  }
}
