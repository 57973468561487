.modal {
  position: fixed;
  transition: all 0.3s ease-in-out;
  top: 100vh;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  &.open {
    top: 0;
  }
  .pricing-tble-grid,
  .grid-Box {
    width: 100%;
  }
  .modal-header {
    padding: 0 20px;
    border-bottom: 1px solid $BorderColor;
    h2.heading {
      margin-bottom: 0 !important;
      font-size: 16px;
    }
  }
  .modal-content {
    background-color: $BackgroundColor;
    margin: auto;
    max-width: 600px;
    width: calc(100vw - 40px);
    border-radius: 10px;
    max-height: calc(100vh - 40px);
    min-height: 200px;
    position: relative;
    overflow: auto;
    &.overflown {
      overflow: unset;
    }
    &.small {
      max-width: 400px;
    }
    &.medium {
      max-width: 600px;
    }
    &.big {
      max-width: 1020px;
    }
    @media (max-width: 768px) {
      min-height: 400px;
      margin: auto auto 0;
    }
    button.close {
      position: absolute;
      right: 0;
      background-color: #ea4335;
      border: 0;
      color: $White;
      display: flex;
      width: 28px;
      z-index: 9;
      top: 0;
      height: 28px;
      ion-icon {
        margin: auto;
        display: block;
        font-size: 20px !important;
      }
    }
    .modal-body {
      display: block;
      &.small {
        max-width: 400px;
      }
      &.medium {
        max-width: 600px;
      }
      &.big {
        max-width: 900px;
      }
      .modal-box {
        overflow: auto;
        margin: 15px 0;
        height: calc(100vh - 308px);
        max-height: 260px;
      }
      .modal-footer {
        .discount-text {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          .MRP {
            text-decoration: line-through;
            color: #b1a9a9;
            padding-right: 10px;
          }
          .offer-percent {
            color: $Success;
          }
        }
        .modalbtn {
          max-width: 180px;
          margin: 20px auto;
        }
      }
    }
  }
}
body.modal-open {
  overflow: hidden;
}
#youtube-video-home-modal,
#youtube-video-modal {
  .modal-body {
    height: 70vh;
  }
}
.ReactModalPortal {
  button.close {
    position: absolute;
    right: 0;
    background-color: #ea4335;
    border: 0;
    color: $White;
    display: flex;
    width: 28px;
    z-index: 9;
    top: 0;
    height: 28px;
    ion-icon {
      margin: auto;
      display: block;
      font-size: 20px !important;
    }
  }
}
.modal-body {
  display: block;
  &.small {
    max-width: 400px;
  }
  &.medium {
    max-width: 600px;
  }
  &.big {
    max-width: 900px;
  }
  .modal-box {
    overflow: auto;
    margin: 15px 0;
    height: calc(100vh - 308px);
    max-height: 260px;
  }
}
