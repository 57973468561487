.mv20 {
    margin-top: 20px;
    margin-bottom: 20px
}
.mv30 {
    margin-top: 30px;
    margin-bottom: 30px
}
.mv40 {
    margin-top: 40px;
    margin-bottom: 40px
}
.mlauto {
    margin-left: auto
}
.mrauto {
    margin-right: auto
}
.mauto {
    margin: auto !important
}
.mhauto {
    margin-right: auto;
    margin-left: auto
}
.mt0 {
    margin-top: 0 !important
}
.m20 {
    margin: 20px !important
}
.mt20 {
    margin-top: 20px !important
}
.mt40 {
    margin-top: 40px !important
}
.mt100 {
    margin-top: 100px !important
}
.mt10 {
    margin-top: 10px !important
}
.mr0 {
    margin-right: 0 !important
}
.mr10 {
    margin-right: 10px !important
}
.mr20 {
    margin-right: 20px !important
}
.mb0 {
    margin-bottom: 0 !important
}
.mb20 {
    margin-bottom: 20px !important
}
.mb24{
    margin-bottom: 24px !important
}
.mb30 {
    margin-bottom: 30px !important
}
.mb40 {
    margin-bottom: 40px !important
}
.mb50 {
    margin-bottom: 50px !important
}
.m50 {
    margin-top: 50px;
    margin-bottom: 50px
}
.mb60 {
    margin-bottom: 60px !important
}
.mb10 {
    margin-bottom: 10px !important
}
.mb60-mbs40 {
    margin-bottom: 60px !important;
    @media (max-width: 600px) {
        margin-bottom: 40px !important
    }
}
.ml0 {
    margin-left: 0 !important
}
.ml20 {
    margin-left: 20px !important
}
.ml10 {
    margin-left: 10px !important
}
.mt-10 {
    margin-top: -10px !important
}
.mtauto {
    margin-top: auto !important
}
.mt30 {
    margin-top: 30px !important
}
.mt50 {
    margin-top: 50px !important
}
.mh-20 {
    margin-left: -20px;
    margin-right: -20px
}
.mt70 {
    margin-top: 70px !important
}
.mt80 {
    margin-top: 80px !important
}
.mh0 {
    @extend .ml0, .mr0
}
.m0 {
    @extend .mb0, .ml0, .mr0, .mt0
}
.pt0 {
    padding-top: 0 !important
}
.pt15 {
    padding-top: 15px !important
}
.p10 {
    padding: 10px !important
}
.pl10 {
    padding-left: 10px
}
.pl20 {
    padding-left: 20px
}
.pl30 {
    padding-left: 30px
}
.pt60 {
    padding-top: 60px
}
.pr0 {
    padding-right: 0 !important
}
.pl0 {
    padding-left: 0 !important
}
.pr20 {
    padding-right: 20px !important
}
.pb0 {
    padding-bottom: 0 !important
}
.pb5 {
    padding-bottom: 5px !important
}
.pb20 {
    padding-bottom: 20px !important
}
.pl0 {
    padding-left: 0 !important
}
.p30 {
    padding: 30px !important
}
.p0 {
    @extend .pb0, .pl0, .pr0, .pt0
}
.pt125 {
    padding-top: 125px
}
.pt120 {
    padding-top: 120px
}
.pv30 {
    padding-top: 30px;
    padding-bottom: 30px
}
.pv40 {
    padding-top: 40px;
    padding-bottom: 40px
}
.pb45 {
    padding-bottom: 45px
}
.pb60 {
    padding-bottom: 60px
}
.pb120 {
    padding-bottom: 120px
}
.pb40 {
    padding-bottom: 40px !important
}
.pb25 {
    padding-bottom: 25px
}
.pb95 {
    padding-bottom: 95px
}
.pb50 {
    padding-bottom: 50px !important
}
.pt25 {
    padding-top: 25px
}
.p20 {
    padding: 20px
}
.pv30 {
    padding-top: 30px;
    padding-bottom: 30px
}
.pv60 {
    padding-top: 60px;
    padding-bottom: 60px
}
.pb65 {
    padding-bottom: 65px
}
.pt40 {
    padding-top: 40px
}
.pt50 {
    padding-top: 50px !important
}
.pt110 {
    padding-top: 110px
}
.pv80 {
    padding-top: 80px;
    padding-bottom: 80px
}
.pl15 {
    padding-left: 15px
}
.pv120 {
    padding-top: 120px;
    padding-bottom: 120px
}
.pv50 {
    padding-top: 50px;
    padding-bottom: 50px
}
.pt125 {
    padding-top: 125px
}
.pb45 {
    padding-bottom: 45px
}
.pt120 {
    padding-top: 120px
}
.pb100 {
    padding-bottom: 100px
}
.pt10 {
    padding-top: 10px
}
.pt20 {
    padding-top: 20px
}
.offset-4 {
    margin-left: 33.333333%
}
.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px
}
.p15 {
    padding: 15px !important
}
.pb10 {
    padding-bottom: 10px
}
.p120 {
    padding: 120px
}
.ml30 {
    margin-left: 30px
}
.pb150 {
    padding-bottom: 150px
}
.pt30 {
    padding-top: 30px !important
}
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding: 0 15px
}
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}
.pb30 {
    padding-bottom: 30px
}
.tac {
    text-align: center !important
}
.tal {
    text-align: left !important
}
.hidden {
    display: none !important
}
.relative {
    position: relative !important
}
.flex {
    display: flex !important
}
.block {
    display: block !important
}
.inline {
    display: inline !important
}
.inline-block {
    display: inline-block !important
}
.pointer {
    cursor: pointer !important
}
.fs12 {
    font-size: 12px !important;
}
@media (min-width: 992px) {
    .mr20-desktop {
        margin-right: 20px !important
    }
}
@media (max-width: 991px) {
    .mr0-tab {
        margin-right: 0 !important
    }
}
.nowrap {
    white-space: nowrap
}
.cursor {
    cursor: pointer !important
}
.success {
    color: $Success !important
}
.warning {
    color: $Yellow !important
}
.error {
    color: $Error !important;
    font-size: 12px;
    box-shadow: none !important;
    background: transparent !important;
    padding: 0 !important
}
.gray {
    color: $Gray !important
}
.white {
    color: $White !important
}
.yellow {
    color: $Yellow !important
}
.ashgray {
    color: $AshGray !important
}
.br0 {
    border-radius: 0 !important
}
.borderbottom {
    border-bottom: 1px solid $BorderColor
}
.border0 {
    border: 0 !important
}
.green {
    color: $Success
}
.widthfull {
    width: 100% !important
}
.widthhalf {
    width: 50% !important
}
.blank {
    user-select: none;
    color: transparent
}
.black {
    color: $Dark
}
.fw500 {
    font-weight: 500
}
.text-capital {
    text-transform: capitalize
}
.background-error {
    background-color: $Error !important;
    color: $White !important
}
.ph20 {
    padding-left: 20px !important;
    padding-right: 20px !important
}
.ph30 {
    padding-left: 30px !important;
    padding-right: 30px !important
}
#root.front {
    .heading-theme {
        font-size: 48px;
        line-height: 70px;
        font-weight: 400;
        @media(max-width: 768px) {
            font-size: 32px;
            line-height: 50px
        }
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 400
    }
}
.font12{
    font-size: 12px
}
.primary{
    color: $Primary
}
.red{
    color: $Error
}
.ellip{
    overflow: hidden;
    text-overflow: ellipsis
}
.overflowVisible {
    overflow: visible !important
}
.max180 {
    max-width: 180px
}
.minheight80 {
    min-height: 80px
}
.flex-columns {
    flex-direction: column !important;
}
.justify-center {
    justify-content: center !important
}
.justify-between {
    justify-content: space-between !important
}
.text-uppercase {
    text-transform: uppercase
}