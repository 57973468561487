.home-slider {
    position: relative;
    .slick-arrow {
        display: none !important;
    }
    .button-box-slick-slider {
        position: absolute;
        top: calc(50% - 25px);
        left: 0;
        right: 0;
        button {
            opacity: 0.4;
            height: 80px;
            width: 80px;
            border-radius: 50% !important;
            line-height: 80px;
            border: 3px solid $Primary !important;
            color: $Primary !important;
            margin-top: -33px;
            background: none;
            font-size: 35px !important;
            justify-content: center;
            display: flex !important;
            align-items: center;
            transition: 0.3s all ease-in-out;
            position: absolute;
            &.button-prev {
                right: auto;
                left: 40px;
                transform: rotate(180deg);
            }
            &.button-next {
                right: 40px;
            }
            &:hover {
                background-color: $Primary !important;
                color: $White !important;
                opacity: 1;
            }
        }
    }
}
.slider-items {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    height: 100vh;
    z-index: 0;
    width: 100%;
    display: inline-block;
    background-color: #f4f4f4;
    background-position: 0 60px;
    position: relative;
    margin-bottom: -10px;
    
   
    @media (max-width: 768px) {
        background-position: bottom center;
    }
    .box-banner {
        margin-top: 80px;
        width: 100%;
        height: 100vh;
        @media(max-width: 1400px) {
            height: 50vw;
            margin-top: 80px
        }
        @media(max-width: 991px) {
            margin-top: 120px;
        }
    }
    .banner-box {
        margin-top: 80px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-height: 100vh;
        @media(max-width: 1400px) {
            height: 50vw;
            margin-top: 80px
        }
        @media(max-width: 991px) {
            margin-top: 120px
        }
    }
    @media (max-width: 1400px) {
        height: 100vh;
        .slick-slide {
            height: 100vh;
        }
        .slick-arrow {
            top: calc(50% + 40px)
        }
    }
    @media (max-width: 991px) {
        height: calc(50vw + 120px);
        padding-top: 120px;
        .slick-slide {
            height: calc(50vw + 120px)
        }
        .slick-arrow {
            top: calc(50% + 60px)
        }
    }
    @media (max-width: 768px) {
        height: auto;
    }
    .slider-items-wrap {
        padding-top: 130px;
        height: 100%
    }
    .slider-inner {
        height: 100vh;
        width: 100%;
        display: flex;
        p {
            color: $White;
            font-size: 18px;   
            margin: 20px 0 30px 0;
            max-height: 85px;
            overflow: hidden
        }
        .slider-img {
            width: 30%;
            display: flex;
            height: 100%;
            align-items: center;
            @media(max-width: 768px) {
                display: none
            }
        }
        .slider-inner-box {
            clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
            background: #000;
            display: flex;
            position: relative;
            width: 70%;
            @media(max-width: 768px) {
                width: 100%;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)
            }
        }
    }
    .slider-content-bottom {
        width: 100%
    }
    .slider-content {
        width: 70%;
        color: $White;
        align-items: center;
        display: flex;
        padding-left: 70px;
        @media(max-width: 768px) {
            padding: 20px;
            width: 100%
        }
        .auto-typing-header {
            margin: 0 auto 15px;
            .typewrite {
                font-size: 16px;
                line-height: 28px;
                font-weight: 700;
                letter-spacing: 10px;
                margin: 0;
                padding: 0;
                display: inline;
                color: transparent;
                position: relative;
                &::before {
                    content: attr(data-text);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    white-space: nowrap;
                    color: $White;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    animation: type 10s infinite
                }
            }   
        }
        .slider-content-inner{
            width: 100%;
            .slider-paragraph {
                font-size: 14px;
                line-height: 30px;
                font-weight: 400;
                width: 75%;
                @media (max-width: 768px) {
                    display: none
                }
            }
            .title {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    -webkit-transform: translateX(-150%);
                    -ms-transform: translateX(-150%);
                    transform: translateX(-150%);
                    z-index: 1;
                    width: 20px;
                    height: 1px;
                    background-color: $White
                }
            }
            .slider-title {
                .heading-01 {
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 3vw;
                    font-weight: 400;
                    line-height: 3.75vw;
                    margin-bottom: 0;
                    color: $White;
                    @media(max-width: 768px)
                    {
                        line-height: normal;
                        font-size: 22px;
                        white-space: normal;
                    }
                    .bold{
                        display: block;
                        font-weight: 500;
                        }
                    }
                    .slider-title-bold .heading-01 {
                        font-size: 65px;
                        line-height: 70px;
                    }
                }
            .main-btn-wrap {
                @media(max-width: 768px) {
                    text-align: center;
                    margin: auto;
                    display: flex;
                    justify-content: center
                }
                .main-btn {
                    text-transform: uppercase
                }
            }
        }
    }
    .jumbo-container{
        height: 100%
    }
    .title {
        color: $White !important
    }
    .right-cut-box {
        position: absolute;
        background: $Primary;
        width: 500px;
        height: 500px;
        bottom: calc(50% - 250px);
        right: 300px;
        transform: rotate(-38deg);
        z-index: -1
    }
    .image-cover-box {
        width: 400px;
        height: 400px;
        background: $AshGray;
        padding: 20px;
        img{
            width: 100%;
            height: 100%
        }
    }
    .slider-title {
        margin: 30px 0;
        @media(max-width: 768px) {
            text-align: center
        }
    }
    .mobile-image-home-banner-box {
        display: none !important;
        @media (max-width: 768px) {
            display: block !important;
            width: 100%;
            max-width: 400px !important;
            max-height: 400px;
            margin: auto
        }
    }
    .mobile-image-home-banner{
        @media (max-width: 768px) {
            width: 100%;
            max-width: 400px !important;
            max-height: 400px
        }
    }
}
.slick-track {
    display: flex;
    justify-content: center;
}
.banner-home {
    width: 100vw !important;
    padding-left: 60px;
    @media (max-width: 768px) {
        padding-left: 40px;
    }
    &.banner-home-bcm {
        background-image: url('https://prod-creditq-media.s3.ap-south-1.amazonaws.com/cms-media/BCM-backgroung.png');
        background-size: cover;
        background-position: right top;
        height: 100vh !important;
    }
}
.slick-slide > div, .slick-slide {
    display: flex;
}