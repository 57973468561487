.cursor {
    cursor: pointer
}
@media( max-width: 600px ) {
    .form-inline {
        >button, >.input, >span {
            flex: 1;
            width: 100%;
            max-width: 100%;
            margin: auto 0 10px auto
        }
    }
}
.img-transaction {
    img {
        width: 20px;
        height: auto
    }
}
.img-icon {
    display: block;
    width: 20px;
    height: auto;
    max-height: 20px;
    cursor: pointer
}
[class$="MenuList"] > div {
    text-align: left !important;
}
h1.page-title {
    font-size: 24px;
    line-height: 32px;
    overflow: visible;
    margin: auto 0 auto 20px !important;
}
#root.admin table thead tr th {
    white-space: nowrap;
}