.pricing-tble-grid-box {
    background: $White;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    overflow: hidden;
    @media(max-width: 768px) {
        box-shadow: none;
        background: transparent
    }
}
.subscription .pricing-tble-grid-box {
    justify-content: center
}
.pricing-tble-grid{
    @media(max-width: 500px) {
        padding: 0 !important
    }
}
.pricing-tble-grid-box {
    justify-content: center;
    margin: auto;
    display: flex; 
    &.activeUpgrade{
        max-width: 530px
    }
    @media(max-width: 768px) {
        max-width: 100%
    }
    .pricing-tble-grid{
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex: 1;
        > div {
            flex: 1
        }
        @media(max-width: 768px) {
            padding-left: 0;
            flex-direction: column
        }
    }
    .pricing-detail-box {
        flex: 1;
        .grid-Box {
            .middle-part{
                .features{
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                    font-size: 10px;
                    ion-icon{
                        align-self: center;
                        color: $Dark
                    }
                }
            }
        }
        @media(max-width: 768px) {
            display: none
        }
    }
    .grid-Box {
        height: max-content;
        min-width: 190px;
        background-color: $White;
        &:first-child(), &:last-child() {
            @media(max-width: 768px) {
                margin: 0 !important
            }
        }
        @media(max-width: 768px) {
            padding: 20px 0;
            overflow: hidden;
            box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
        }
        .upper-part{
            height: 56px;
            padding: 20px 10px;
            line-height: 30px;
            text-align: center;
            .plan-heading{
                white-space: nowrap;
                color: $Dark;
                font-weight: 600;
                letter-spacing: 1px;
                position: relative
            }
        }
        .middle-part{
            padding: 30px 0;
            .features{
                border-bottom: 1px solid $BorderColor;
                height: 40px;
                text-align: left;
                line-height: 40px;
                padding: 0 0 0 20px;
                font-size: 10px;
                position: relative;
                @media(max-width: 1024px) {
                    line-height: 60px;
                }
                @media(max-width: 768px) {
                    padding: 0 10px;
                    line-height: 40px;
                }
                &::before {
                    position: absolute;
                    content: '';
                    left: 20px;
                    font-weight: 600;
                    opacity: 0.6;
                    @media(max-width: 430px) {
                        font-size: 10px;
                    }
                }
                .feature-details {
                    max-width: 130px;
                    margin: auto;
                    text-align: left;
                    @media(max-width: 768px) {
                        max-width: 22%;
                        text-align: left;
                        margin-left: auto !important;
                        margin: 0
                    }
                    @media(max-width: 500px) {
                        max-width: 22%;
                        font-size: 12px
                    }
                    @media(max-width: 430px) {
                        max-width: 17%;
                        font-size: 12px;
                    }
                }
                ion-icon{
                    color: $Primary;
                    vertical-align: middle;
                    margin-right: 10px;
                    font-size: 16px !important;
                    @media(max-width: 430px) {
                        display: none;
                    }
                }
                @media(max-width: 768px) {
                    &.box1{
                        &::before{
                            content: 'Plan Fee'
                        }
                    }
                    &.box2{
                        &::before{
                            content: 'No. of Free Registration of Companies'
                        }
                    }
                    &.box3{
                        &::before{
                            content: 'Paid Registration of Further Companies';
                           
                        }
                    }
                    &.box4{
                        &::before{
                            content: 'Membership Duration'
                        }
                    }
                    &.box5{
                        &::before{
                            content: 'Report Defaulter Cashback'
                        }
                    }
                    &.box6{
                        &::before{
                            content: 'Referral Cashback*'
                        }
                    }
                    &.box7{
                        &::before{
                            content: 'CIR Generation Fees for further CIRs'
                        }
                    }
                }
            }
        }
        .bottom-part .btn{
            margin: auto
        }
    }
    .grid-box-center{
        position: relative;
        .plan-heading{
            p.plan-subheading {
                padding-top: 30px
            }
        }
        .most-popular{
            position: absolute;
            background-color: $Primary;
            position: absolute;
            color: $White;
            padding: 0 10px;
            left: 90px;
            top: 60px;
            font-size: 10px;
            @media(max-width: 768px) {
                left: -30px;
                background-color: #5380be;
                position: absolute;
                color: #ffffff;
                padding: 0 40px;
                top: 27px;
                font-size: 10px;
                transform: rotate(-38deg);
            }
            &::after{position: absolute;
                content: '';
                top: -19px;
                border: 10px solid transparent;
                border-bottom: 10px solid $Primary;
                left: 5px
            }
        }
    }
}
.pricing-table-background{
    border-radius: 30px;
    position: relative;
    @media(max-width: 768px) {
        padding: 50px 0
    }
}
.pricing-header-background{
    background-color: #ffa5001c;
    clip-path: polygon(0 1%, 100% 0, 69% 81%, 0% 100%);
    position: absolute;
    height: calc(100vh - 200px);
    top: 0;
    left: 0;
    right: 0;
    width: 1440px;
    margin: 150px auto;
    border-radius: 42px;
    z-index: 0
}
.heading-section-pricing{
    text-align: center;
    .empty-subscriptions {
        img{
            opacity: 0.7;
            margin: auto;
        }
        button{
            margin: 20px auto;
        }
    }
    .heading-table-price{
        line-height: 60px;
        font-size: 50px;
        font-weight: 500;
        &::after {
            display: block;
            content: '';
            background: $Primary;
            width: 60px;
            height: 2px;
            margin-top: 13px;
            margin: auto
        }
    }
    p{
        opacity: 0.75
    }
}
.heading-table-price-p{
    &::after {
        display: block;
        content: '';
        background: $Primary;
        width: 60px;
        height: 2px;
        margin-top: 13px;
        margin: auto
    }
}
.header-inner {
    padding: 50px;
    margin: 150px 0;
    display: flex;
    position: relative;
    z-index: 9;
    .content-left {
        flex: 1;
        align-self: center;
        .box-header{
            line-height: 60px;
            font-size: 50px;
            font-weight: 500;
            &::after {
                display: block;
                content: '';
                background: $Primary;
                width: 40px;
                height: 2px;
                margin-top: 13px
            }
        }
    }
    .image-right{
        align-self: center;
        flex: 1
    }
}
.arrow-box {
    position: absolute;
    bottom: 80px;
    margin: auto;
    left: 50%;
    background: $White;
    z-index: 999;
    width: 60px;
    height: 80px;
    border-radius: 30px;
    box-shadow: 0px -1px 19px 3px rgba(0, 0, 0, 0.33);
    &::before {
        position: absolute;
        content: '';
        border: 2px solid #a9a2a2;
        border-radius: 38px;
        width: 72px;
        height: 66px;
        top: -8px;
        border-bottom: 0 !important;
        left: -8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }
    ion-icon.md.hydrated {
        color: $Primary;
        font-size: 22px !important;
        justify-content: center;
        margin: auto;
        display: flex;
        height: 100%
    }
}