#root.admin{
    .columns {
        display: flex;
        .left {
            flex: 3;
            padding-right: 20px
        }
        @media (max-width: 600px) {
            flex-direction: column;
            .left {
                padding-right: 0
            }
        }
        .right {
            flex: 1
        }
    }
    .banner{
        .box{
            .body{
                overflow: unset;
                max-height: 100%
            }
        }
    }
    .box {
        background-color: $White;
        position: relative;
        margin-bottom: 20px;
        border-radius: 20px;
        box-shadow: 0 1px 2px $ShadowColor;
        h4.head {
            line-height: 28px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            margin: 0;
            cursor: pointer;
            font-size: 18px;
            padding: 0 40px 0 20px;
            line-height: 40px;
            font-weight: 400;
            border-bottom: 1px solid $TableBackground;
            user-select: none;
            &::after {
                content: '';
                position: absolute;
                border: 6px solid transparent;
                border-top: 10px solid $TableBackground;
                right: 15px;
                top: 16px
            }
        }
        .body {
            padding: 10px 20px 20px;
            transition: all .3s ease-in-out;
            max-height: 540px;
            overflow: auto;
            &.overflown {
                overflow: visible
            }
        }
        &.closed{
            .head{
                margin-bottom: 0;
                border-bottom: 0;
            }
            .body{
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                max-height: 0;
            }
        }
    }
    .switch {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
        &.on {
            background-color: $Success
        }
        &.off {
            background-color: $Error
        }
    }
}