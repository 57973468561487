.badge {
    color: #fff;
    display: inline-block;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 6px;
    background-color: #9fadc1;
    &.badge-green {
        background-color: #7bbc44;
    }
    &.badge-red {
        background-color: #dd5743;
    }
}